import { isEmpty, isNotEmpty } from '~/utils/common'
import codeConst from '~/utils/codeConst'

export function getPaymentPrice(item, paymentType, PAYMENT_TYPE, local) {
  if (
    paymentType === PAYMENT_TYPE.LOCAL_CREDIT_CARD.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_MONEY_TRANSFER.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_CELLULAR_PHONE.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_CULTURAL_GIFT.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_KAKAOPAY.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_PAYCO.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_MONEY_DEPOSIT.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_NAVERPAY.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_NAVERPAY_POINT.CD ||
    (paymentType === PAYMENT_TYPE.DIRECT_MONEY_TRANSFER.CD && local === 'ko') ||
    (paymentType === PAYMENT_TYPE.FREE.CD && local === 'ko') ||
    paymentType === PAYMENT_TYPE.LOCAL_TOSS_VIRTUAL_ACCOUNT.CD
  ) {
    return Math.ceil(item.krw)
  } else if (
    paymentType === PAYMENT_TYPE.GLOBAL_ECONTEXT.CD ||
    (paymentType === PAYMENT_TYPE.GLOBAL_CREDIT_CARD.CD && local === 'ja') ||
    (paymentType === PAYMENT_TYPE.DIRECT_MONEY_TRANSFER.CD && local === 'ja') ||
    (paymentType === PAYMENT_TYPE.FREE.CD && local === 'ja')
  ) {
    return Math.ceil(item.jpy)
  } else if (
    paymentType === PAYMENT_TYPE.GLOBAL_ALIPAY.CD ||
    paymentType === PAYMENT_TYPE.GLOBAL_WECHAT.CD ||
    paymentType === PAYMENT_TYPE.GLOBAL_ALIPAY_ICB.CD ||
    paymentType === PAYMENT_TYPE.GLOBAL_ALIPAY_ICB_GB.CD ||
    paymentType === PAYMENT_TYPE.GLOBAL_WECHAT_ICB.CD ||
    paymentType === PAYMENT_TYPE.GLOBAL_MOLPAY.CD ||
    paymentType === PAYMENT_TYPE.GLOBAL_PAYPAL.CD ||
    paymentType === PAYMENT_TYPE.GLOBAL_CREDIT_CARD.CD ||
    paymentType === PAYMENT_TYPE.DIRECT_MONEY_TRANSFER.CD ||
    paymentType === PAYMENT_TYPE.FREE.CD ||
    paymentType === PAYMENT_TYPE.TOSS_PAYPAL.CD
  ) {
    return Number(item.usd.toFixed(2))
  }
}

/**
 * 국가 전화번호 코드 계산
 * @param {string} selectCountry
 * @param {Array<{ cntryCd: string, cntryI18n: string, telNoCd: array }>} countryList
 * @returns {*|string}
 */
export function labelTelNoCd(selectCountry, countryList) {
  if (isEmpty(countryList) || isEmpty(selectCountry)) {
    return ''
  }

  if (
    selectCountry === 'DO' ||
    selectCountry === 'PR' ||
    selectCountry === 'AS' ||
    selectCountry === 'VG' ||
    selectCountry === 'AI' ||
    selectCountry === 'BM' ||
    selectCountry === 'KY' ||
    selectCountry === 'MS' ||
    selectCountry === 'TC' ||
    selectCountry === 'GU' ||
    selectCountry === 'MP' ||
    selectCountry === 'VI'

  ) {
    return '+1'
  }

  const selectedCountry = countryList.find(country => country.cntryCd === selectCountry)

  if (selectedCountry && selectedCountry.telNoCd) {
    const telNoCode = selectedCountry.telNoCd
    if (telNoCode.length >= 2) {
      return '+1'
    }
    return telNoCode[0]
  }

  return ''
}

export function labelPayStat(payStat, PAY_STAT, payMtd, PAYMENT_TYPE, rfdPrcStat, RFD_PRC_STAT, partialRefundYn) {
  if (isEmpty(payStat)) {
    return ''
  }

  if (partialRefundYn === 'Y' && payStat === 'E') {
    return RFD_PRC_STAT['P'].NM
  }

  if (isNotEmpty(rfdPrcStat)) {
    return RFD_PRC_STAT[rfdPrcStat].NM
  }

  if (isEmpty(payMtd)) {
    return PAY_STAT[payStat].NM
  }

  if (payStat === PAY_STAT.W.CD && (payMtd === PAYMENT_TYPE.LOCAL_MONEY_DEPOSIT.CD || payMtd === PAYMENT_TYPE.LOCAL_TOSS_VIRTUAL_ACCOUNT.CD)) {
    return this.$t('payMent.virtualAccountWaiting')
  }

  return PAY_STAT[payStat].NM
}

export function getFixedPriceByLocale(locale, price) {
  switch (locale) {
    case codeConst.LANGUAGE.EN:
      price = Math.ceil(Math.floor(price * 1000) / 10) / 100
      break
    case codeConst.LANGUAGE.ES:
      price = Math.ceil(Math.floor(price * 1000) / 10) / 100
      break
    case codeConst.LANGUAGE.ZH:
      price = Math.ceil(Math.floor(price * 1000) / 10) / 100
      break
    case codeConst.LANGUAGE.KO:
      price = Math.ceil(price)
      break
    case codeConst.LANGUAGE.JA:
      price = Math.ceil(price)
      break
  }
  return price
}

export function isVirtualAccount(payMtd, PAYMENT_TYPE) {
  if (isEmpty(payMtd)) {
    return false
  }

  if (payMtd === PAYMENT_TYPE.LOCAL_MONEY_DEPOSIT.CD || payMtd === PAYMENT_TYPE.LOCAL_TOSS_VIRTUAL_ACCOUNT.CD) {
    return true
  }

  return false
}

/**
 * 신분증 번호 앞자리를 마스킹합니다.
 * @param {string} identityNumber
 * @returns {string}
 */
export function maskIdentityNumber(identityNumber) {
  const visiblePart = identityNumber.slice(-4)
  return visiblePart.padStart(identityNumber.length, '*')
}
