import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2ac5037c = () => interopDefault(import('../pages/artists/index.vue' /* webpackChunkName: "pages/artists/index" */))
const _79a4840e = () => interopDefault(import('../pages/chart/index.vue' /* webpackChunkName: "pages/chart/index" */))
const _9c019af4 = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _50c5c380 = () => interopDefault(import('../pages/lb-health.vue' /* webpackChunkName: "pages/lb-health" */))
const _6c211bd6 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _372591bb = () => interopDefault(import('../pages/notice.vue' /* webpackChunkName: "pages/notice" */))
const _368bb39e = () => interopDefault(import('../pages/partnership.vue' /* webpackChunkName: "pages/partnership" */))
const _6a601be5 = () => interopDefault(import('../pages/password-retrieval.vue' /* webpackChunkName: "pages/password-retrieval" */))
const _86ac8480 = () => interopDefault(import('../pages/poca.vue' /* webpackChunkName: "pages/poca" */))
const _0272c23c = () => interopDefault(import('../pages/powerofwhelm.vue' /* webpackChunkName: "pages/powerofwhelm" */))
const _075ab3d4 = () => interopDefault(import('../pages/qna/index.vue' /* webpackChunkName: "pages/qna/index" */))
const _2bedaa64 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _c59a545a = () => interopDefault(import('../pages/specificCommercialLaw.vue' /* webpackChunkName: "pages/specificCommercialLaw" */))
const _7e7c3566 = () => interopDefault(import('../pages/userqna.vue' /* webpackChunkName: "pages/userqna" */))
const _949b1760 = () => interopDefault(import('../pages/vote/index.vue' /* webpackChunkName: "pages/vote/index" */))
const _031696d1 = () => interopDefault(import('../pages/about/marketing.vue' /* webpackChunkName: "pages/about/marketing" */))
const _e02ad01a = () => interopDefault(import('../pages/about/privacy.vue' /* webpackChunkName: "pages/about/privacy" */))
const _46f7ff32 = () => interopDefault(import('../pages/about/terms.vue' /* webpackChunkName: "pages/about/terms" */))
const _b1b6891c = () => interopDefault(import('../pages/payment/order.vue' /* webpackChunkName: "pages/payment/order" */))
const _8a17bda4 = () => interopDefault(import('../pages/payment/paymentRefundStep.vue' /* webpackChunkName: "pages/payment/paymentRefundStep" */))
const _b8b0a418 = () => interopDefault(import('../pages/payment/paymentRefundStep2.vue' /* webpackChunkName: "pages/payment/paymentRefundStep2" */))
const _bc8a39e6 = () => interopDefault(import('../pages/popup/invoiceHistory.vue' /* webpackChunkName: "pages/popup/invoiceHistory" */))
const _1f7d07e3 = () => interopDefault(import('../pages/projects/paymentStep3.vue' /* webpackChunkName: "pages/projects/paymentStep3" */))
const _57cd7ed8 = () => interopDefault(import('../pages/projects/updates.vue' /* webpackChunkName: "pages/projects/updates" */))
const _d50aa7b2 = () => interopDefault(import('../pages/projects/wishlist.vue' /* webpackChunkName: "pages/projects/wishlist" */))
const _0128abee = () => interopDefault(import('../pages/projects/wishlist_origin.vue' /* webpackChunkName: "pages/projects/wishlist_origin" */))
const _c68ac0c4 = () => interopDefault(import('../pages/result/change-email.vue' /* webpackChunkName: "pages/result/change-email" */))
const _2ec6afee = () => interopDefault(import('../pages/result/change-password.vue' /* webpackChunkName: "pages/result/change-password" */))
const _1e603924 = () => interopDefault(import('../pages/result/duplication-email.vue' /* webpackChunkName: "pages/result/duplication-email" */))
const _12f26112 = () => interopDefault(import('../pages/result/invalid-email.vue' /* webpackChunkName: "pages/result/invalid-email" */))
const _53da9168 = () => interopDefault(import('../pages/result/leaveres.vue' /* webpackChunkName: "pages/result/leaveres" */))
const _21d5b7be = () => interopDefault(import('../pages/result/password-retrieval.vue' /* webpackChunkName: "pages/result/password-retrieval" */))
const _b6450528 = () => interopDefault(import('../pages/shop/B2BAccessOnly.vue' /* webpackChunkName: "pages/shop/B2BAccessOnly" */))
const _4a743c86 = () => interopDefault(import('../pages/shop/cart.vue' /* webpackChunkName: "pages/shop/cart" */))
const _628db810 = () => interopDefault(import('../pages/shop/payment.vue' /* webpackChunkName: "pages/shop/payment" */))
const _217e195c = () => interopDefault(import('../pages/shop/payment2.vue' /* webpackChunkName: "pages/shop/payment2" */))
const _4cecb9ca = () => interopDefault(import('../pages/shop/products.vue' /* webpackChunkName: "pages/shop/products" */))
const _34987e7c = () => interopDefault(import('../pages/signup/send-auth/index.vue' /* webpackChunkName: "pages/signup/send-auth/index" */))
const _6ab6164a = () => interopDefault(import('../pages/signup/send-email.vue' /* webpackChunkName: "pages/signup/send-email" */))
const _dc11da5e = () => interopDefault(import('../pages/signup/step1.vue' /* webpackChunkName: "pages/signup/step1" */))
const _dbf5ab5c = () => interopDefault(import('../pages/signup/step2.vue' /* webpackChunkName: "pages/signup/step2" */))
const _cb2443d4 = () => interopDefault(import('../pages/signup/step3/index.vue' /* webpackChunkName: "pages/signup/step3/index" */))
const _83832f1a = () => interopDefault(import('../pages/signup/terms.vue' /* webpackChunkName: "pages/signup/terms" */))
const _19c3547a = () => interopDefault(import('../pages/social/auth/index.vue' /* webpackChunkName: "pages/social/auth/index" */))
const _51e3ca2a = () => interopDefault(import('../pages/social/signup/index.vue' /* webpackChunkName: "pages/social/signup/index" */))
const _3d3bd356 = () => interopDefault(import('../pages/user/leave.vue' /* webpackChunkName: "pages/user/leave" */))
const _72e2ff88 = () => interopDefault(import('../pages/user/profile.vue' /* webpackChunkName: "pages/user/profile" */))
const _0353d744 = () => interopDefault(import('../pages/v1/imgViewer.vue' /* webpackChunkName: "pages/v1/imgViewer" */))
const _1bb052d5 = () => interopDefault(import('../pages/v1/payment.vue' /* webpackChunkName: "pages/v1/payment" */))
const _23c8e938 = () => interopDefault(import('../pages/v1/resultPayment.vue' /* webpackChunkName: "pages/v1/resultPayment" */))
const _2dc95513 = () => interopDefault(import('../pages/contents/events/gayotop10/index.vue' /* webpackChunkName: "pages/contents/events/gayotop10/index" */))
const _2d1922da = () => interopDefault(import('../pages/payment/orders/step.vue' /* webpackChunkName: "pages/payment/orders/step" */))
const _7622e1c8 = () => interopDefault(import('../pages/payment/orders/step2.vue' /* webpackChunkName: "pages/payment/orders/step2" */))
const _4d72f780 = () => interopDefault(import('../pages/social/signup/step1/index.vue' /* webpackChunkName: "pages/social/signup/step1/index" */))
const _083cef42 = () => interopDefault(import('../pages/social/signup/terms.vue' /* webpackChunkName: "pages/social/signup/terms" */))
const _c1b70b06 = () => interopDefault(import('../pages/signup/step3/components/input.vue' /* webpackChunkName: "pages/signup/step3/components/input" */))
const _60978a5a = () => interopDefault(import('../pages/social/signup/step1/components/input.vue' /* webpackChunkName: "pages/social/signup/step1/components/input" */))
const _62a1f582 = () => interopDefault(import('../pages/contents/articles/detail/_id/index.vue' /* webpackChunkName: "pages/contents/articles/detail/_id/index" */))
const _07737048 = () => interopDefault(import('../pages/contents/polls/detail/_id/index.vue' /* webpackChunkName: "pages/contents/polls/detail/_id/index" */))
const _45735980 = () => interopDefault(import('../pages/contents/polls/list/_status/index.vue' /* webpackChunkName: "pages/contents/polls/list/_status/index" */))
const _30f1fe47 = () => interopDefault(import('../pages/user/me/qna/_qnaNo.vue' /* webpackChunkName: "pages/user/me/qna/_qnaNo" */))
const _2c14ce68 = () => interopDefault(import('../pages/contents/articles/_type/index.vue' /* webpackChunkName: "pages/contents/articles/_type/index" */))
const _45ee7884 = () => interopDefault(import('../pages/payment/orders/_type/index.vue' /* webpackChunkName: "pages/payment/orders/_type/index" */))
const _74284e66 = () => interopDefault(import('../pages/projects/list/_type/index.vue' /* webpackChunkName: "pages/projects/list/_type/index" */))
const _61c44c51 = () => interopDefault(import('../pages/shop/exhibit/_id/index.vue' /* webpackChunkName: "pages/shop/exhibit/_id/index" */))
const _78747d74 = () => interopDefault(import('../pages/shop/list/_category/_cd/index.vue' /* webpackChunkName: "pages/shop/list/_category/_cd/index" */))
const _8973d96a = () => interopDefault(import('../pages/projects/_id/index.vue' /* webpackChunkName: "pages/projects/_id/index" */))
const _b7c886ee = () => interopDefault(import('../pages/projects/_id/index/cheer.vue' /* webpackChunkName: "pages/projects/_id/index/cheer" */))
const _3f7aeecc = () => interopDefault(import('../pages/projects/_id/index/notice.vue' /* webpackChunkName: "pages/projects/_id/index/notice" */))
const _201eb8ba = () => interopDefault(import('../pages/projects/_id/index/reward.vue' /* webpackChunkName: "pages/projects/_id/index/reward" */))
const _22fe879e = () => interopDefault(import('../pages/projects/_id/index/story.vue' /* webpackChunkName: "pages/projects/_id/index/story" */))
const _0ff85600 = () => interopDefault(import('../pages/projects/_id/index/update/index.vue' /* webpackChunkName: "pages/projects/_id/index/update/index" */))
const _31ec3720 = () => interopDefault(import('../pages/projects/_id/index/update/index/detail.vue' /* webpackChunkName: "pages/projects/_id/index/update/index/detail" */))
const _5cd26a47 = () => interopDefault(import('../pages/shop/_id/index.vue' /* webpackChunkName: "pages/shop/_id/index" */))
const _d5c2a382 = () => interopDefault(import('../pages/projects/_id/payment.vue' /* webpackChunkName: "pages/projects/_id/payment" */))
const _ac8c11d2 = () => interopDefault(import('../pages/projects/_id/paymentStep2.vue' /* webpackChunkName: "pages/projects/_id/paymentStep2" */))
const _02738af5 = () => interopDefault(import('../pages/artists/_id/_category/index.vue' /* webpackChunkName: "pages/artists/_id/_category/index" */))
const _bb0d0e82 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/artists",
    component: _2ac5037c,
    meta: {"name":"Artist","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"artist","onMenuRouteName":"Artist"},
    name: "artists"
  }, {
    path: "/chart",
    component: _79a4840e,
    meta: {"name":"Main","components":{},"filters":{},"computed":{},"methods":{},"onMenuRouteName":"Chart"},
    name: "chart"
  }, {
    path: "/faq",
    component: _9c019af4,
    meta: {"name":"Example","directives":{},"filters":{},"middleware":"","computed":{},"methods":{},"fullSize":true,"pageTitle":"faq"},
    name: "faq"
  }, {
    path: "/lb-health",
    component: _50c5c380,
    meta: {"name":"LBHealthChecker","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{}},
    name: "lb-health"
  }, {
    path: "/login",
    component: _6c211bd6,
    meta: {"name":"Login","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"watch":{},"methods":{},"pageTitle":"login","onMenuRouteName":""},
    name: "login"
  }, {
    path: "/notice",
    component: _372591bb,
    meta: {"name":"NoticeList","directives":{},"components":{},"filters":{},"middleware":"","head":{},"computed":{},"methods":{}},
    name: "notice"
  }, {
    path: "/partnership",
    component: _368bb39e,
    meta: {"name":"Partnership","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"fullSize":false,"onMenuRouteName":"Partner"},
    name: "partnership"
  }, {
    path: "/password-retrieval",
    component: _6a601be5,
    meta: {"name":"PasswordRetrieval","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"passwordRetrieval","onMenuRouteName":""},
    name: "password-retrieval"
  }, {
    path: "/poca",
    component: _86ac8480,
    meta: {"name":"Poca","components":{},"computed":{},"methods":{},"fullSize":false,"onMenuRouteName":"PocaAlbum","pageTitle":"pocaalbum"},
    name: "poca"
  }, {
    path: "/powerofwhelm",
    component: _0272c23c,
    meta: {"name":"Powerofwhelm","computed":{},"watch":{},"methods":{}},
    name: "powerofwhelm"
  }, {
    path: "/qna",
    component: _075ab3d4,
    meta: {"name":"QnA","directives":{},"components":{},"computed":{},"methods":{},"pageTitle":"qna","onMenuRouteName":"","auth":true},
    name: "qna"
  }, {
    path: "/search",
    component: _2bedaa64,
    meta: {"name":"Search","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"watch":{},"methods":{},"onMenuRouteName":"","pageTitle":"search"},
    name: "search"
  }, {
    path: "/specificCommercialLaw",
    component: _c59a545a,
    meta: {"name":"AboutPrivacy","directives":{},"components":{},"filters":{},"middleware":"","head":{},"computed":{},"methods":{}},
    name: "specificCommercialLaw"
  }, {
    path: "/userqna",
    component: _7e7c3566,
    meta: {"name":"UserQnA","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"inquiry","onMenuRouteName":"","auth":true},
    name: "userqna"
  }, {
    path: "/vote",
    component: _949b1760,
    meta: {"name":"Vote","components":{},"pageTitle":"poll","onMenuRouteName":"Vote"},
    name: "vote"
  }, {
    path: "/about/marketing",
    component: _031696d1,
    meta: {"name":"AboutMarketing","directives":{},"components":{},"filters":{},"middleware":"","methods":{},"pageTitle":"aboutMarketing","onMenuRouteName":""},
    name: "about-marketing"
  }, {
    path: "/about/privacy",
    component: _e02ad01a,
    meta: {"name":"AboutPrivacy","directives":{},"components":{},"filters":{},"middleware":"","head":{},"methods":{}},
    name: "about-privacy"
  }, {
    path: "/about/terms",
    component: _46f7ff32,
    meta: {"name":"AboutTerms","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"aboutTerms","onMenuRouteName":""},
    name: "about-terms"
  }, {
    path: "/payment/order",
    component: _b1b6891c,
    meta: {"name":"NewProductOrder","components":{},"computed":{"phone":{},"paymentType":{},"deliveryCode":{},"selectCountry":{},"zipCode":{},"address":{},"rewardPrice":{}},"methods":{}},
    name: "payment-order"
  }, {
    path: "/payment/paymentRefundStep",
    component: _8a17bda4,
    meta: {"name":"PaymentRefundStep","computed":{},"watch":{},"methods":{},"auth":true},
    name: "payment-paymentRefundStep"
  }, {
    path: "/payment/paymentRefundStep2",
    component: _b8b0a418,
    meta: {"name":"PaymentRefundStep","computed":{},"watch":{},"methods":{},"auth":true},
    name: "payment-paymentRefundStep2"
  }, {
    path: "/popup/invoiceHistory",
    component: _bc8a39e6,
    meta: {"name":"InvoiceHistory","components":{},"computed":{},"methods":{},"pageTitle":"deliveryTracking","auth":true},
    name: "popup-invoiceHistory"
  }, {
    path: "/projects/paymentStep3",
    component: _1f7d07e3,
    meta: {"name":"PaymentStep2","computed":{},"methods":{},"auth":true,"pageTitle":"payment"},
    name: "projects-paymentStep3"
  }, {
    path: "/projects/updates",
    component: _57cd7ed8,
    meta: {"name":"Search","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"update","onMenuRouteName":""},
    name: "projects-updates"
  }, {
    path: "/projects/wishlist",
    component: _d50aa7b2,
    meta: {"name":"Wishlist","components":{},"computed":{},"methods":{},"auth":true,"onMenuRouteName":"Wishlist","fullSize":true,"pageTitle":"wishlist"},
    name: "projects-wishlist"
  }, {
    path: "/projects/wishlist_origin",
    component: _0128abee,
    meta: {"name":"Wishlist","components":{},"head":{"title":"Shop | K-POP Makestar"},"computed":{},"methods":{},"auth":true,"onMenuRouteName":"Wishlist","fullSize":true},
    name: "projects-wishlist_origin"
  }, {
    path: "/result/change-email",
    component: _c68ac0c4,
    meta: {"name":"PasswordRetrievalResult","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"passwordRetrieval","onMenuRouteName":""},
    name: "result-change-email"
  }, {
    path: "/result/change-password",
    component: _2ec6afee,
    meta: {"name":"PasswordRetrievalResult","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"passwordRetrieval","onMenuRouteName":""},
    name: "result-change-password"
  }, {
    path: "/result/duplication-email",
    component: _1e603924,
    meta: {"name":"DuplicationEmail","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"","onMenuRouteName":""},
    name: "result-duplication-email"
  }, {
    path: "/result/invalid-email",
    component: _12f26112,
    meta: {"name":"PasswordRetrievalResult","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"invalidEmail","onMenuRouteName":""},
    name: "result-invalid-email"
  }, {
    path: "/result/leaveres",
    component: _53da9168,
    meta: {"name":"LeaveRes","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"watch":{},"methods":{},"pageTitle":"회원탈퇴","onMenuRouteName":"","fullSize":true},
    name: "result-leaveres"
  }, {
    path: "/result/password-retrieval",
    component: _21d5b7be,
    meta: {"name":"PasswordRetrievalResult","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"passwordRetrieval","onMenuRouteName":""},
    name: "result-password-retrieval"
  }, {
    path: "/shop/B2BAccessOnly",
    component: _b6450528,
    meta: {"name":"B2BAccessOnly","components":{},"computed":{},"methods":{},"fullSize":false,"onMenuRouteName":"B2BAccessOnly"},
    name: "shop-B2BAccessOnly"
  }, {
    path: "/shop/cart",
    component: _4a743c86,
    meta: {"name":"Cart","components":{},"computed":{},"methods":{},"auth":true,"onMenuRouteName":"Cart","fullSize":true,"pageTitle":"cart"},
    name: "shop-cart"
  }, {
    path: "/shop/payment",
    component: _628db810,
    meta: {"name":"Payment","computed":{},"watch":{},"methods":{},"auth":true,"pageTitle":"payment"},
    name: "shop-payment"
  }, {
    path: "/shop/payment2",
    component: _217e195c,
    meta: {"name":"ShopStep","components":{},"computed":{},"methods":{},"auth":true,"pageTitle":"payment"},
    name: "shop-payment2"
  }, {
    path: "/shop/products",
    component: _4cecb9ca,
    meta: {"name":"ShopProducts","directives":{},"filters":{},"middleware":"","computed":{},"watch":{},"methods":{},"onMenuRouteName":"Shop","fullSize":true},
    name: "shop-products"
  }, {
    path: "/signup/send-auth",
    component: _34987e7c,
    meta: {"name":"SendAuth","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"watch":{},"methods":{}},
    name: "signup-send-auth"
  }, {
    path: "/signup/send-email",
    component: _6ab6164a,
    meta: {"name":"SignUp","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"signup","onMenuRouteName":""},
    name: "signup-send-email"
  }, {
    path: "/signup/step1",
    component: _dc11da5e,
    meta: {"name":"SignUpStep1","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"watch":{},"methods":{},"pageTitle":"signup","onMenuRouteName":""},
    name: "signup-step1"
  }, {
    path: "/signup/step2",
    component: _dbf5ab5c,
    meta: {"name":"SignUpStep2","directives":{},"filters":{},"middleware":"","props":{},"computed":{},"watch":{},"methods":{},"pageTitle":"signup","onMenuRouteName":""},
    name: "signup-step2"
  }, {
    path: "/signup/step3",
    component: _cb2443d4,
    meta: {"name":"Step2","directives":{},"filters":{},"components":{},"middleware":"","props":{},"computed":{},"methods":{},"pageTitle":"signup","onMenuRouteName":""},
    name: "signup-step3"
  }, {
    path: "/signup/terms",
    component: _83832f1a,
    meta: {"name":"SignUpTerms","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"signup","onMenuRouteName":""},
    name: "signup-terms"
  }, {
    path: "/social/auth",
    component: _19c3547a,
    meta: {"name":"SocialAuthPopup","methods":{}},
    name: "social-auth"
  }, {
    path: "/social/signup",
    component: _51e3ca2a,
    meta: {"name":"SocialSignup","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"signup","onMenuRouteName":""},
    name: "social-signup"
  }, {
    path: "/user/leave",
    component: _3d3bd356,
    meta: {"name":"Leave","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"watch":{},"methods":{},"pageTitle":"leave","onMenuRouteName":"","fullSize":true,"auth":true},
    name: "user-leave"
  }, {
    path: "/user/profile",
    component: _72e2ff88,
    meta: {"name":"UserProfile","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"watch":{},"methods":{},"pageTitle":"profile","onMenuRouteName":"","fullSize":true,"auth":true},
    name: "user-profile"
  }, {
    path: "/v1/imgViewer",
    component: _0353d744,
    meta: {"name":"ImgViewer"},
    name: "v1-imgViewer"
  }, {
    path: "/v1/payment",
    component: _1bb052d5,
    meta: {"name":"Payment"},
    name: "v1-payment"
  }, {
    path: "/v1/resultPayment",
    component: _23c8e938,
    meta: {"name":"ResultPayment","computed":{},"methods":{}},
    name: "v1-resultPayment"
  }, {
    path: "/contents/events/gayotop10",
    component: _2dc95513,
    meta: {"name":"GayoTop10","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"GayoTop10","onMenuRouteName":"GayoTop10","fullSize":true},
    name: "contents-events-gayotop10"
  }, {
    path: "/payment/orders/step",
    component: _2d1922da,
    meta: {"name":"PaymentOrdersDetail","components":{},"computed":{},"watch":{},"methods":{},"auth":true,"fullSize":false,"pageTitle":"orderDetail"},
    name: "payment-orders-step"
  }, {
    path: "/payment/orders/step2",
    component: _7622e1c8,
    meta: {"name":"PaymentOrdersDetail","components":{},"computed":{},"methods":{},"auth":true,"fullSize":false},
    name: "payment-orders-step2"
  }, {
    path: "/social/signup/step1",
    component: _4d72f780,
    meta: {"name":"Step2","directives":{},"filters":{},"components":{},"middleware":"","props":{},"computed":{},"methods":{},"pageTitle":"signup","onMenuRouteName":""},
    name: "social-signup-step1"
  }, {
    path: "/social/signup/terms",
    component: _083cef42,
    meta: {"name":"SocialSignUpTerms","computed":{},"methods":{},"pageTitle":"signup","onMenuRouteName":""},
    name: "social-signup-terms"
  }, {
    path: "/signup/step3/components/input",
    component: _c1b70b06,
    meta: {"props":{"validRegexResult":{},"validRegex":{},"value":{"default":""},"placeholder":{"default":""},"key1":{"default":""},"componentType":{"default":"text"},"max":{"default":0}},"watch":{},"methods":{}},
    name: "signup-step3-components-input"
  }, {
    path: "/social/signup/step1/components/input",
    component: _60978a5a,
    meta: {"props":{"validRegexResult":{},"validRegex":{},"value":{"default":""},"placeholder":{"default":""},"key1":{"default":""},"componentType":{"default":"text"},"max":{"default":0}},"watch":{},"methods":{}},
    name: "social-signup-step1-components-input"
  }, {
    path: "/contents/articles/detail/:id",
    component: _62a1f582,
    meta: {"name":"EventDetail","components":{},"computed":{},"methods":{},"fullSize":true},
    name: "contents-articles-detail-id"
  }, {
    path: "/contents/polls/detail/:id",
    component: _07737048,
    meta: {"name":"PollsDetail","components":{},"computed":{},"methods":{}},
    name: "contents-polls-detail-id"
  }, {
    path: "/contents/polls/list/:status",
    component: _45735980,
    meta: {"name":"Polls","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"onMenuRouteName":"Event","fullSize":true},
    name: "contents-polls-list-status"
  }, {
    path: "/user/me/qna/:qnaNo?",
    component: _30f1fe47,
    meta: {"name":"QnADetail","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"watch":{},"methods":{},"pageTitle":"qnaInfo","onMenuRouteName":"","auth":true},
    name: "user-me-qna-qnaNo"
  }, {
    path: "/contents/articles/:type",
    component: _2c14ce68,
    meta: {"name":"Articles","directives":{},"filters":{},"components":{},"middleware":"","computed":{},"methods":{},"onMenuRouteName":"Event"},
    name: "contents-articles-type"
  }, {
    path: "/payment/orders/:type",
    component: _45ee7884,
    meta: {"name":"Orders","components":{},"computed":{},"methods":{},"auth":true,"fullSize":true,"pageTitle":"payments"},
    name: "payment-orders-type"
  }, {
    path: "/projects/list/:type",
    component: _74284e66,
    meta: {"name":"ProjectArtist","components":{},"computed":{},"methods":{},"onMenuRouteName":"Project","fullSize":true,"pageTitle":"project"},
    name: "projects-list-type"
  }, {
    path: "/shop/exhibit/:id",
    component: _61c44c51,
    meta: {"components":{},"filters":{},"head":{"title":"Shop | K-POP Makestar","fullSize":true},"computed":{},"methods":{},"onMenuRouteName":"Shop","fullSize":true},
    name: "shop-exhibit-id"
  }, {
    path: "/shop/list/:category?/:cd",
    component: _78747d74,
    meta: {"name":"ShopProducts","components":{},"computed":{},"watch":{},"methods":{},"onMenuRouteName":"Shop","fullSize":true,"pageTitle":"shop"},
    name: "shop-list-category-cd"
  }, {
    path: "/projects/:id",
    component: _8973d96a,
    meta: {"name":"Index","components":{},"computed":{},"methods":{},"fullSize":true},
    name: "projects-id",
    children: [{
      path: "cheer",
      component: _b7c886ee,
      name: "projects-id-index-cheer"
    }, {
      path: "notice",
      component: _3f7aeecc,
      name: "projects-id-index-notice"
    }, {
      path: "reward",
      component: _201eb8ba,
      name: "projects-id-index-reward"
    }, {
      path: "story",
      component: _22fe879e,
      name: "projects-id-index-story"
    }, {
      path: "update",
      component: _0ff85600,
      name: "projects-id-index-update",
      children: [{
        path: "detail",
        component: _31ec3720,
        name: "projects-id-index-update-index-detail"
      }]
    }]
  }, {
    path: "/shop/:id",
    component: _5cd26a47,
    meta: {"name":"","filters":{},"computed":{},"components":{},"watch":{"undefined":{}},"methods":{}},
    name: "shop-id"
  }, {
    path: "/projects/:id?/payment",
    component: _d5c2a382,
    meta: {"name":"Payment","components":{},"computed":{},"watch":{},"methods":{},"auth":true,"pageTitle":"payment"},
    name: "projects-id-payment"
  }, {
    path: "/projects/:id?/paymentStep2",
    component: _ac8c11d2,
    meta: {"name":"PaymentStep2","components":{},"computed":{},"watch":{},"methods":{},"auth":true,"pageTitle":"payment"},
    name: "projects-id-paymentStep2"
  }, {
    path: "/artists/:id/:category",
    component: _02738af5,
    meta: {"name":"Artist","directives":{},"components":{},"filters":{},"middleware":"","computed":{},"methods":{},"pageTitle":"artists","onMenuRouteName":"Artist"},
    name: "artists-id-category"
  }, {
    path: "/",
    component: _bb0d0e82,
    meta: {"name":"Home","directives":{},"components":{},"filters":{},"computed":{},"watch":{},"methods":{},"fullSize":true},
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
