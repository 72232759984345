// eslint-disable-next-line no-unused-vars
import { rest } from 'msw'
// import { RESPONSE_IN_DELIVERY_PROGRESS, RESPONSE_CONTAINS_REFUND } from '~/mocks/data'

// eslint-disable-next-line no-unused-vars
const BASE_URL = `${process.env.WEB_URL}/_n`

export const handlers = [
  // rest.get(`https://web-api.makestar.com/shopping/v1/home`, (req, res, ctx) => {
  //   return res(
  //     ctx.status(200),
  //     ctx.json({
  //       resCd: '1000',
  //       resMsg: 'success',
  //       resData: {
  //         ctgrList: [
  //           {
  //             dpCtgrCd: '',
  //             ctgrNm: '전체 상품'
  //           },
  //           {
  //             dpCtgrCd: 'DCI048',
  //             ctgrNm: 'SONG GEON HEE FANMEETING MD [First Page] in Seoul'
  //           },
  //           {
  //             dpCtgrCd: 'DCI049',
  //             ctgrNm: 'SONG GEON HEE FANMEETING MD [First Page] in Taipei'
  //           },
  //           {
  //             dpCtgrCd: 'DCI046',
  //             ctgrNm: 'EVNNE LUCKY DRAW [ TOKYO 온라인 예약 ]'
  //           },
  //           {
  //             dpCtgrCd: 'DCI010',
  //             ctgrNm: '최신 상품'
  //           },
  //           {
  //             dpCtgrCd: 'DCI001',
  //             ctgrNm: '케이팝 앨범'
  //           },
  //           {
  //             dpCtgrCd: 'DCI028',
  //             ctgrNm: '케이팝 MD'
  //           },
  //           {
  //             dpCtgrCd: 'DCI024',
  //             ctgrNm: '시즌그리팅'
  //           },
  //           {
  //             dpCtgrCd: 'DCI017',
  //             ctgrNm: '드라마'
  //           },
  //           {
  //             dpCtgrCd: 'DCI026',
  //             ctgrNm: '트로트'
  //           },
  //           {
  //             dpCtgrCd: 'DCI027',
  //             ctgrNm: '글로벌 MD'
  //           },
  //           {
  //             dpCtgrCd: 'DCI044',
  //             ctgrNm: '82MAJOR MD'
  //           },
  //           {
  //             dpCtgrCd: 'DCI043',
  //             ctgrNm: "LE'V MD"
  //           }
  //         ],
  //         bnList: [
  //           {
  //             linkDiv: 'U',
  //             pdCd: '',
  //             linkUrl: 'https://www.makestar.co/shop/exhibit/PE010?utm_source=banner&utm_medium=banner&utm_campaign=PE010&utm_content=open',
  //             nwYn: 'N',
  //             fileUrl: 'https://cfw-makestar-staging.s3.ap-northeast-2.amazonaws.com',
  //             mobileFileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/0623/스토어 배너_in seoul_v3.jpg'
  //           },
  //           {
  //             linkDiv: 'U',
  //             pdCd: '',
  //             linkUrl: 'https://makestar.co/shop/exhibit/PE011 ?utm_source=banner&utm_medium=banner&utm_campaign=PE011&utm_content=open',
  //             nwYn: 'N',
  //             fileUrl: 'https://cfw-makestar-staging.s3.ap-northeast-2.amazonaws.com',
  //             mobileFileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/0623/스토어 배너_in taipei_v3.jpg'
  //           },
  //           {
  //             linkDiv: 'U',
  //             pdCd: '',
  //             linkUrl: 'https://makestar.co/shop/exhibit/PE009?utm_source=banner&utm_medium=banner&utm_campaign=PE009&utm_content=open',
  //             nwYn: 'N',
  //             fileUrl: 'https://d238m8ukn6hkhb.cloudfront.net',
  //             mobileFileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/0623/스토어 배너_르세라핌.jpg'
  //           },
  //           {
  //             linkDiv: 'U',
  //             pdCd: '',
  //             linkUrl: ' https://makestar.co/shop/exhibit/PE008?utm_source=banner&utm_medium=banner&utm_campaign=PE008&utm_content=open',
  //             nwYn: 'N',
  //             fileUrl: 'https://cfw-makestar-staging.s3.ap-northeast-2.amazonaws.com',
  //             mobileFileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/0623/스토어 배너_kr_v3.jpg'
  //           },
  //           {
  //             linkDiv: 'U',
  //             pdCd: '',
  //             linkUrl: 'https://www.makestar.co/shop/exhibit/PE006?utm_source=banner&utm_medium=banner&utm_campaign=PE006&utm_content=open',
  //             nwYn: 'N',
  //             fileUrl: 'https://cfw-makestar-staging.s3.ap-northeast-2.amazonaws.com',
  //             mobileFileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/DKZ/스토어 배너_UKISS_KR.jpg'
  //           },
  //           {
  //             linkDiv: 'U',
  //             pdCd: '',
  //             linkUrl: 'https://www.makestar.co/shop/exhibit/PE005?utm_source=banner&utm_medium=banner&utm_campaign=PE005&utm_content=open',
  //             nwYn: 'N',
  //             fileUrl: 'https://cfw-makestar-staging.s3.ap-northeast-2.amazonaws.com',
  //             mobileFileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/DKZ/스토어 배너_XG.jpg'
  //           }
  //         ],
  //         popList: null,
  //         recomList: [
  //           {
  //             pdCd: 'PD37885',
  //             pdNm: '드림캐쳐 (Dreamcatcher) 데뷔 싱글 [악몽(惡夢)]',
  //             price: 23300,
  //             salePrice: 19300,
  //             fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/DKZ/[지니뮤직] 드림캐쳐 싱글1집 [악몽] 썸네일.jpg',
  //             dcYn: 'Y',
  //             dcTermYn: 'N',
  //             dcStDt: null,
  //             dcEdDt: null,
  //             dcDiv: 'P',
  //             dcRatio: 0,
  //             dcFee: 4000,
  //             regDttm: '2024-08-22 05:28:07',
  //             saleStat: 'S',
  //             stkCntDpYn: 'N',
  //             stkCnt: 49919,
  //             erInfoI18n: {
  //               ko: 1200.69,
  //               en: 1,
  //               zh: 7.05,
  //               ja: 144.96,
  //               es: 0.9
  //             },
  //             langCrcy: 'KRW',
  //             dpCtgrCd: null,
  //             iconNew: '',
  //             recomYn: 'N',
  //             ctgrNm: '케이팝 앨범',
  //             badgeList: [],
  //             pdType: 'N',
  //             specialPdYn: 'N',
  //             displaySaleTerm: 'N',
  //             displaySaleQuantity: 'N',
  //             totalSaleQuantity: 0,
  //             saleStDt: '2024-08-22 06:00:00',
  //             saleEdDt: null,
  //             saleEdTermYn: 'Y',
  //             brandIdx: 0
  //           },
  //           {
  //             pdCd: 'PD37884',
  //             pdNm: '드림캐쳐 (Dreamcatcher) 싱글 2집 [악몽- Fall asleep in the mirror]',
  //             price: 23300,
  //             salePrice: 19300,
  //             fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/DKZ/[지니뮤직] 드림캐쳐 싱글2집 [악몽 - Fall asleep in the mirror] 썸네일.jpg',
  //             dcYn: 'Y',
  //             dcTermYn: 'N',
  //             dcStDt: null,
  //             dcEdDt: null,
  //             dcDiv: 'P',
  //             dcRatio: 0,
  //             dcFee: 4000,
  //             regDttm: '2024-08-22 05:22:38',
  //             saleStat: 'S',
  //             stkCntDpYn: 'N',
  //             stkCnt: 49926,
  //             erInfoI18n: {
  //               ko: 1200.69,
  //               en: 1,
  //               zh: 7.05,
  //               ja: 144.96,
  //               es: 0.9
  //             },
  //             langCrcy: 'KRW',
  //             dpCtgrCd: null,
  //             iconNew: '',
  //             recomYn: 'N',
  //             ctgrNm: '케이팝 앨범',
  //             badgeList: [],
  //             pdType: 'N',
  //             specialPdYn: 'N',
  //             displaySaleTerm: 'N',
  //             displaySaleQuantity: 'N',
  //             totalSaleQuantity: 0,
  //             saleStDt: '2024-08-22 06:00:00',
  //             saleEdDt: null,
  //             saleEdTermYn: 'Y',
  //             brandIdx: 0
  //           },
  //           {
  //             pdCd: 'PD37747',
  //             pdNm: "V 'Layover' LP",
  //             price: 62000,
  //             salePrice: 52000,
  //             fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/DKZ/V_Layover-LP_Thumbnail(F) (1).jpg',
  //             dcYn: 'Y',
  //             dcTermYn: 'N',
  //             dcStDt: null,
  //             dcEdDt: null,
  //             dcDiv: 'P',
  //             dcRatio: 0,
  //             dcFee: 10000,
  //             regDttm: '2024-08-20 01:49:13',
  //             saleStat: 'S',
  //             stkCntDpYn: 'N',
  //             stkCnt: 10,
  //             erInfoI18n: {
  //               ko: 1195.88,
  //               en: 1,
  //               zh: 7.05,
  //               ja: 146.16,
  //               es: 0.9
  //             },
  //             langCrcy: 'KRW',
  //             dpCtgrCd: null,
  //             iconNew: '',
  //             recomYn: 'N',
  //             ctgrNm: '케이팝 앨범',
  //             badgeList: [
  //               {
  //                 fileUrl: 'https://d238m8ukn6hkhb.cloudfront.net/file/product_badges/1.1/badge_text_limited_v2.png',
  //                 badgeIdx: 8,
  //                 priority: 0,
  //                 created_date: '2022-04-05 07:57:47.000000',
  //                 name: 'LIMITED'
  //               }
  //             ],
  //             pdType: 'N',
  //             specialPdYn: 'N',
  //             displaySaleTerm: 'N',
  //             displaySaleQuantity: 'N',
  //             totalSaleQuantity: 0,
  //             saleStDt: '2024-08-20 02:00:00',
  //             saleEdDt: null,
  //             saleEdTermYn: 'Y',
  //             brandIdx: 0
  //           },
  //           {
  //             pdCd: 'PD37436',
  //             pdNm: 'BOYNEXTDOOR 3rd EP [19.99] (3종 세트)',
  //             price: 74400,
  //             salePrice: 59400,
  //             fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/DKZ/01_BND_3EP_Standard-ver_Thumbnail (1).jpg',
  //             dcYn: 'Y',
  //             dcTermYn: 'N',
  //             dcStDt: null,
  //             dcEdDt: null,
  //             dcDiv: 'P',
  //             dcRatio: 0,
  //             dcFee: 15000,
  //             regDttm: '2024-08-13 01:52:49',
  //             saleStat: 'S',
  //             stkCntDpYn: 'N',
  //             stkCnt: 50000,
  //             erInfoI18n: {
  //               ko: 1231.25,
  //               en: 1,
  //               zh: 7.1,
  //               ja: 147.13,
  //               es: 0.91
  //             },
  //             langCrcy: 'KRW',
  //             dpCtgrCd: null,
  //             iconNew: '',
  //             recomYn: 'N',
  //             ctgrNm: '케이팝 앨범',
  //             badgeList: [],
  //             pdType: 'N',
  //             specialPdYn: 'N',
  //             displaySaleTerm: 'N',
  //             displaySaleQuantity: 'N',
  //             totalSaleQuantity: 0,
  //             saleStDt: '2024-08-13 02:00:00',
  //             saleEdDt: null,
  //             saleEdTermYn: 'Y',
  //             brandIdx: 0
  //           },
  //           {
  //             pdCd: 'PD37482',
  //             pdNm: '백현(BAEKHYUN) 미니앨범 4집 [Hello, World] (Photobook Ver.) (2종 세트)',
  //             price: 38600,
  //             salePrice: 32600,
  //             fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/DKZ/[BH]PHOTOBOOK-B-01.jpg',
  //             dcYn: 'Y',
  //             dcTermYn: 'N',
  //             dcStDt: null,
  //             dcEdDt: null,
  //             dcDiv: 'P',
  //             dcRatio: 0,
  //             dcFee: 6000,
  //             regDttm: '2024-08-13 04:50:52',
  //             saleStat: 'S',
  //             stkCntDpYn: 'N',
  //             stkCnt: 49963,
  //             erInfoI18n: {
  //               ko: 1231.07,
  //               en: 1,
  //               zh: 7.1,
  //               ja: 147.27,
  //               es: 0.91
  //             },
  //             langCrcy: 'KRW',
  //             dpCtgrCd: null,
  //             iconNew: '',
  //             recomYn: 'N',
  //             ctgrNm: '케이팝 앨범',
  //             badgeList: [],
  //             pdType: 'N',
  //             specialPdYn: 'N',
  //             displaySaleTerm: 'N',
  //             displaySaleQuantity: 'N',
  //             totalSaleQuantity: 0,
  //             saleStDt: '2024-08-13 05:00:00',
  //             saleEdDt: null,
  //             saleEdTermYn: 'Y',
  //             brandIdx: 0
  //           },
  //           {
  //             pdCd: 'PD37283',
  //             pdNm: '찬열 미니 1집 [Black Out] (Photo Book Ver.) (2종 세트)',
  //             price: 43600,
  //             salePrice: 35600,
  //             fileUrl: "https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/(1)/L700001456_신보안내서_찬열 미니 1집 'Black Out'(Photo Book Ver.)_자켓이미지 (1).jpg",
  //             dcYn: 'Y',
  //             dcTermYn: 'N',
  //             dcStDt: null,
  //             dcEdDt: null,
  //             dcDiv: 'P',
  //             dcRatio: 0,
  //             dcFee: 8000,
  //             regDttm: '2024-08-09 05:36:15',
  //             saleStat: 'S',
  //             stkCntDpYn: 'N',
  //             stkCnt: 49928,
  //             erInfoI18n: {
  //               ko: 1224.72,
  //               en: 1,
  //               zh: 7.08,
  //               ja: 146.68,
  //               es: 0.92
  //             },
  //             langCrcy: 'KRW',
  //             dpCtgrCd: null,
  //             iconNew: '',
  //             recomYn: 'N',
  //             ctgrNm: '케이팝 앨범',
  //             badgeList: [],
  //             pdType: 'N',
  //             specialPdYn: 'N',
  //             displaySaleTerm: 'N',
  //             displaySaleQuantity: 'N',
  //             totalSaleQuantity: 0,
  //             saleStDt: '2024-08-09 06:00:00',
  //             saleEdDt: '2024-08-27 14:59:59',
  //             saleEdTermYn: 'N',
  //             brandIdx: 0
  //           },
  //           {
  //             pdCd: 'PD37057',
  //             pdNm: '쯔위 (TWICE) [abouTZU] (2종 랜덤)',
  //             price: 22600,
  //             salePrice: 18600,
  //             fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/DKZ/TY_M1_일반반_대표이미지.jpg',
  //             dcYn: 'Y',
  //             dcTermYn: 'N',
  //             dcStDt: null,
  //             dcEdDt: null,
  //             dcDiv: 'P',
  //             dcRatio: 0,
  //             dcFee: 4000,
  //             regDttm: '2024-08-06 04:07:21',
  //             saleStat: 'S',
  //             stkCntDpYn: 'N',
  //             stkCnt: 49983,
  //             erInfoI18n: {
  //               ko: 1233.07,
  //               en: 1,
  //               zh: 7.05,
  //               ja: 145.15,
  //               es: 0.91
  //             },
  //             langCrcy: 'KRW',
  //             dpCtgrCd: null,
  //             iconNew: '',
  //             recomYn: 'N',
  //             ctgrNm: '케이팝 앨범',
  //             badgeList: [],
  //             pdType: 'N',
  //             specialPdYn: 'N',
  //             displaySaleTerm: 'N',
  //             displaySaleQuantity: 'N',
  //             totalSaleQuantity: 0,
  //             saleStDt: '2024-08-06 04:00:00',
  //             saleEdDt: null,
  //             saleEdTermYn: 'Y',
  //             brandIdx: 0
  //           }
  //         ],
  //         liveShop: [],
  //         peList: [
  //           {
  //             peCd: 'PE011',
  //             peNm: "'2024 송건희 팬미팅 [First Page] in TAIPEI' 공식 MD 예약 판매",
  //             peOperStDttm: '2024-08-23 06:00:00',
  //             peOperEdDttm: '2024-09-20 14:59:59',
  //             fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/0623/기획전 배너_in taipei_v3.jpg'
  //           },
  //           {
  //             peCd: 'PE010',
  //             peNm: "'2024 송건희 첫 번째 팬미팅 [First Page] in SEOUL' 공식 MD 판매",
  //             peOperStDttm: '2024-08-23 06:00:00',
  //             peOperEdDttm: '2024-09-20 14:59:59',
  //             fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/0623/기획전 배너_in seoul_v3.jpg'
  //           },
  //           {
  //             peCd: 'PE009',
  //             peNm: 'LE SSERAFIM 4th Mini Album [CRAZY] PRE-ORDER OPEN',
  //             peOperStDttm: '2024-08-20 07:00:00',
  //             peOperEdDttm: '2024-08-30 03:59:59',
  //             fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/0623/기획전 배너_르세라핌.jpg'
  //           },
  //           {
  //             peCd: 'PE008',
  //             peNm: '케이팝 솔로 아티스트 ✨HOT 컴백✨',
  //             peOperStDttm: '2024-08-13 15:00:00',
  //             peOperEdDttm: '2024-09-05 14:59:59',
  //             fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/0623/기획전 배너_kr_v3.jpg'
  //           }
  //         ],
  //         peCnt: 6,
  //         lsCnt: 0,
  //         ctgrProductList: {
  //           DCI027: [
  //             {
  //               pdCd: 'PD16777',
  //               pdNm: '진정령 2019콘서트 DVD (3DICS)',
  //               price: 128400,
  //               salePrice: 128400,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/theuntamed08/common_01_item_dvd.png',
  //               dcYn: 'N',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'R',
  //               dcRatio: 10,
  //               dcFee: 0,
  //               regDttm: '2023-02-27 09:12:25',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 49,
  //               erInfoI18n: {
  //                 ko: 1231.52,
  //                 en: 1,
  //                 zh: 7.13,
  //                 ja: 149.63,
  //                 es: 0.93
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI027',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '글로벌 MD',
  //               badgeList: [
  //                 {
  //                   fileUrl: 'https://d238m8ukn6hkhb.cloudfront.net/file/product_badges/1.1/badge_text_hot_v2.png',
  //                   badgeIdx: 7,
  //                   priority: 0,
  //                   created_date: '2022-04-05 07:57:40.000000',
  //                   name: 'HOT'
  //                 }
  //               ],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2023-02-27 09:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD11840',
  //               pdNm: '진정령 3주념 기념 아크릴 등신대',
  //               price: 21500,
  //               salePrice: 21500,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/theuntamed08/theuntamed_acrylic_01.jpg',
  //               dcYn: 'N',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'R',
  //               dcRatio: 0,
  //               dcFee: 0,
  //               regDttm: '2022-08-29 09:03:22',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 149,
  //               erInfoI18n: {
  //                 ko: 1231.52,
  //                 en: 1,
  //                 zh: 7.13,
  //                 ja: 149.63,
  //                 es: 0.93
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI027',
  //               iconNew: '',
  //               recomYn: 'Y',
  //               ctgrNm: '글로벌 MD',
  //               badgeList: [
  //                 {
  //                   fileUrl: 'https://d238m8ukn6hkhb.cloudfront.net/file/product_badges/1.1/badge_text_hot_v2.png',
  //                   badgeIdx: 7,
  //                   priority: 0,
  //                   created_date: '2022-04-05 07:57:40.000000',
  //                   name: 'HOT'
  //                 },
  //                 {
  //                   fileUrl: 'https://d238m8ukn6hkhb.cloudfront.net/file/product_badges/1.1/badge_text_preorder_v2.png',
  //                   badgeIdx: 10,
  //                   priority: 0,
  //                   created_date: '2022-04-05 07:58:00.000000',
  //                   name: 'PRE-Order'
  //                 }
  //               ],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2022-08-29 09:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD8291',
  //               pdNm: '진정령-평안희락 기복주머니',
  //               price: 19500,
  //               salePrice: 19500,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/theuntamed2203/Lucky Pouch-1.jpg',
  //               dcYn: 'N',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'R',
  //               dcRatio: 0,
  //               dcFee: 0,
  //               regDttm: '2022-04-05 10:41:15',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 1,
  //               erInfoI18n: {
  //                 ko: 1231.52,
  //                 en: 1,
  //                 zh: 7.13,
  //                 ja: 149.63,
  //                 es: 0.93
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI027',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '글로벌 MD',
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2022-04-05 11:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD7705',
  //               pdNm: '진정령-진정키링',
  //               price: 16100,
  //               salePrice: 13700,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/theuntamed2203/진정-1.jpg',
  //               dcYn: 'Y',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'R',
  //               dcRatio: 15,
  //               dcFee: 0,
  //               regDttm: '2022-03-08 09:07:15',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 12,
  //               erInfoI18n: {
  //                 ko: 1231.52,
  //                 en: 1,
  //                 zh: 7.13,
  //                 ja: 149.63,
  //                 es: 0.93
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI027',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '글로벌 MD',
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2022-03-08 11:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             }
  //           ],
  //           DCI049: [
  //             {
  //               pdCd: 'PD37937',
  //               pdNm: 'SONG GEON HEE FANMEETING MD [First Page] in TAIPEI "FABRIC SLOGAN"',
  //               price: 22000,
  //               salePrice: 22000,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/(1)/songgeonhee_02_shop_thumb_06.jpg',
  //               dcYn: 'N',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'R',
  //               dcRatio: 0,
  //               dcFee: 0,
  //               regDttm: '2024-08-22 08:36:33',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 236,
  //               erInfoI18n: {
  //                 ko: 1200.32,
  //                 en: 1,
  //                 zh: 7.05,
  //                 ja: 145.1,
  //                 es: 0.9
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI049',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '최신 상품',
  //               badgeList: [
  //                 {
  //                   fileUrl: 'https://d238m8ukn6hkhb.cloudfront.net/file/product_badges/1.1/badge_text_mksonly_v2.png',
  //                   badgeIdx: 9,
  //                   priority: 0,
  //                   created_date: '2022-04-05 07:57:54.000000',
  //                   name: 'MAKESTAR Only'
  //                 }
  //               ],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-08-23 06:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD37936',
  //               pdNm: 'SONG GEON HEE FANMEETING MD [First Page] in TAIPEI "FAN"',
  //               price: 12000,
  //               salePrice: 12000,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/(1)/songgeonhee_02_shop_thumb_05.jpg',
  //               dcYn: 'N',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'R',
  //               dcRatio: 0,
  //               dcFee: 0,
  //               regDttm: '2024-08-22 08:33:12',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 243,
  //               erInfoI18n: {
  //                 ko: 1200.32,
  //                 en: 1,
  //                 zh: 7.05,
  //                 ja: 145.1,
  //                 es: 0.9
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI049',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '최신 상품',
  //               badgeList: [
  //                 {
  //                   fileUrl: 'https://d238m8ukn6hkhb.cloudfront.net/file/product_badges/1.1/badge_text_mksonly_v2.png',
  //                   badgeIdx: 9,
  //                   priority: 0,
  //                   created_date: '2022-04-05 07:57:54.000000',
  //                   name: 'MAKESTAR Only'
  //                 }
  //               ],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-08-23 06:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD37935',
  //               pdNm: 'SONG GEON HEE FANMEETING MD [First Page] in TAIPEI "ACRYLIC KEYRING"',
  //               price: 12000,
  //               salePrice: 12000,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/(1)/songgeonhee_02_shop_thumb_04.jpg',
  //               dcYn: 'N',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'R',
  //               dcRatio: 0,
  //               dcFee: 0,
  //               regDttm: '2024-08-22 08:23:40',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 362,
  //               erInfoI18n: {
  //                 ko: 1200.32,
  //                 en: 1,
  //                 zh: 7.05,
  //                 ja: 145.1,
  //                 es: 0.9
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI049',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '최신 상품',
  //               badgeList: [
  //                 {
  //                   fileUrl: 'https://d238m8ukn6hkhb.cloudfront.net/file/product_badges/1.1/badge_text_mksonly_v2.png',
  //                   badgeIdx: 9,
  //                   priority: 0,
  //                   created_date: '2022-04-05 07:57:54.000000',
  //                   name: 'MAKESTAR Only'
  //                 }
  //               ],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-08-23 06:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD37934',
  //               pdNm: 'SONG GEON HEE FANMEETING MD [First Page] in TAIPEI "POSTCARD & STAMP-TYPE MASKING TAPE SET"',
  //               price: 17000,
  //               salePrice: 17000,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/(1)/songgeonhee_02_shop_thumb_03.jpg',
  //               dcYn: 'N',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'R',
  //               dcRatio: 0,
  //               dcFee: 0,
  //               regDttm: '2024-08-22 08:19:41',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 231,
  //               erInfoI18n: {
  //                 ko: 1200.32,
  //                 en: 1,
  //                 zh: 7.05,
  //                 ja: 145.1,
  //                 es: 0.9
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI049',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '최신 상품',
  //               badgeList: [
  //                 {
  //                   fileUrl: 'https://d238m8ukn6hkhb.cloudfront.net/file/product_badges/1.1/badge_text_mksonly_v2.png',
  //                   badgeIdx: 9,
  //                   priority: 0,
  //                   created_date: '2022-04-05 07:57:54.000000',
  //                   name: 'MAKESTAR Only'
  //                 }
  //               ],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-08-23 06:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             }
  //           ],
  //           DCI026: [
  //             {
  //               pdCd: 'PD37565',
  //               pdNm: '영탁 (YOUNGTAK) 미니앨범 [SuperSuper] (DIGIPACK Ver.) (2종 세트)',
  //               price: 32800,
  //               salePrice: 26800,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/OST/L200003091_신보안내서_영탁 미니 (DIGIPACK)_자켓이미지.jpg',
  //               dcYn: 'Y',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'P',
  //               dcRatio: 0,
  //               dcFee: 6000,
  //               regDttm: '2024-08-14 03:59:57',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 50000,
  //               erInfoI18n: {
  //                 ko: 1222.63,
  //                 en: 1,
  //                 zh: 7.06,
  //                 ja: 146.26,
  //                 es: 0.91
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI026',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '최신 상품',
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-08-14 05:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD37564',
  //               pdNm: '영탁 (YOUNGTAK) 미니앨범 [SuperSuper] (DIGIPACK Ver.) (2종 랜덤)',
  //               price: 16400,
  //               salePrice: 13400,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/OST/L200003091_신보안내서_영탁 미니 (DIGIPACK)_자켓이미지.jpg',
  //               dcYn: 'Y',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'P',
  //               dcRatio: 0,
  //               dcFee: 3000,
  //               regDttm: '2024-08-14 03:58:24',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 50000,
  //               erInfoI18n: {
  //                 ko: 1222.63,
  //                 en: 1,
  //                 zh: 7.06,
  //                 ja: 146.26,
  //                 es: 0.91
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI026',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '최신 상품',
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-08-14 05:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD37563',
  //               pdNm: '영탁 (YOUNGTAK)\t미니앨범 [SuperSuper]',
  //               price: 27300,
  //               salePrice: 22300,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/DKZ/L200003090_신보안내서_영탁 미니_자켓이미지 (1).jpg',
  //               dcYn: 'Y',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'P',
  //               dcRatio: 0,
  //               dcFee: 5000,
  //               regDttm: '2024-08-14 03:51:05',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 49999,
  //               erInfoI18n: {
  //                 ko: 1222.63,
  //                 en: 1,
  //                 zh: 7.06,
  //                 ja: 146.26,
  //                 es: 0.91
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI026',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '최신 상품',
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-08-14 05:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD32784',
  //               pdNm: '나태주 2nd Mini Album [나,다움 아름,다움]',
  //               price: 17900,
  //               salePrice: 14900,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/OST/나태주_판매썸네일 (1).jpg',
  //               dcYn: 'Y',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'P',
  //               dcRatio: 0,
  //               dcFee: 3000,
  //               regDttm: '2024-05-02 09:02:10',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 50000,
  //               erInfoI18n: {
  //                 ko: 1231.52,
  //                 en: 1,
  //                 zh: 7.13,
  //                 ja: 149.63,
  //                 es: 0.93
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI026',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '트로트',
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-05-08 00:14:44',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             }
  //           ],
  //           DCI048: [
  //             {
  //               pdCd: 'PD37931',
  //               pdNm: 'SONG GEON HEE FANMEETING MD [First Page] in SEOUL "ECO-BAG"',
  //               price: 22000,
  //               salePrice: 22000,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/(1)/songgeonhee_01_shop_thumb_06.jpg',
  //               dcYn: 'N',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'R',
  //               dcRatio: 0,
  //               dcFee: 0,
  //               regDttm: '2024-08-22 07:47:29',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 66,
  //               erInfoI18n: {
  //                 ko: 1199.05,
  //                 en: 1,
  //                 zh: 7.05,
  //                 ja: 145.16,
  //                 es: 0.9
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI048',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '최신 상품',
  //               badgeList: [
  //                 {
  //                   fileUrl: 'https://d238m8ukn6hkhb.cloudfront.net/file/product_badges/1.1/badge_text_mksonly_v2.png',
  //                   badgeIdx: 9,
  //                   priority: 0,
  //                   created_date: '2022-04-05 07:57:54.000000',
  //                   name: 'MAKESTAR Only'
  //                 }
  //               ],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-08-23 06:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD37930',
  //               pdNm: 'SONG GEON HEE FANMEETING MD [First Page] in SEOUL "FAN"',
  //               price: 12000,
  //               salePrice: 12000,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/(1)/songgeonhee_01_shop_thumb_05.jpg',
  //               dcYn: 'N',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'R',
  //               dcRatio: 0,
  //               dcFee: 0,
  //               regDttm: '2024-08-22 07:38:02',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 6,
  //               erInfoI18n: {
  //                 ko: 1199.05,
  //                 en: 1,
  //                 zh: 7.05,
  //                 ja: 145.16,
  //                 es: 0.9
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI048',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '최신 상품',
  //               badgeList: [
  //                 {
  //                   fileUrl: 'https://d238m8ukn6hkhb.cloudfront.net/file/product_badges/1.1/badge_text_mksonly_v2.png',
  //                   badgeIdx: 9,
  //                   priority: 0,
  //                   created_date: '2022-04-05 07:57:54.000000',
  //                   name: 'MAKESTAR Only'
  //                 }
  //               ],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-08-23 06:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD37929',
  //               pdNm: 'SONG GEON HEE FANMEETING MD [First Page] in SEOUL "ACRYLIC KEYRING"',
  //               price: 12000,
  //               salePrice: 12000,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/(1)/songgeonhee_01_shop_thumb_04.jpg',
  //               dcYn: 'N',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'R',
  //               dcRatio: 0,
  //               dcFee: 0,
  //               regDttm: '2024-08-22 07:35:31',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 99,
  //               erInfoI18n: {
  //                 ko: 1199.05,
  //                 en: 1,
  //                 zh: 7.05,
  //                 ja: 145.16,
  //                 es: 0.9
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI048',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '최신 상품',
  //               badgeList: [
  //                 {
  //                   fileUrl: 'https://d238m8ukn6hkhb.cloudfront.net/file/product_badges/1.1/badge_text_mksonly_v2.png',
  //                   badgeIdx: 9,
  //                   priority: 0,
  //                   created_date: '2022-04-05 07:57:54.000000',
  //                   name: 'MAKESTAR Only'
  //                 }
  //               ],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-08-23 06:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD37928',
  //               pdNm: 'SONG GEON HEE FANMEETING MD [First Page] in SEOUL "POSTCARD & STAMP-TYPE MASKING TAPE SET"',
  //               price: 17000,
  //               salePrice: 17000,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/(1)/songgeonhee_01_shop_thumb_03.jpg',
  //               dcYn: 'N',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'R',
  //               dcRatio: 0,
  //               dcFee: 0,
  //               regDttm: '2024-08-22 07:28:11',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 25,
  //               erInfoI18n: {
  //                 ko: 1199.05,
  //                 en: 1,
  //                 zh: 7.05,
  //                 ja: 145.16,
  //                 es: 0.9
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI048',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '최신 상품',
  //               badgeList: [
  //                 {
  //                   fileUrl: 'https://d238m8ukn6hkhb.cloudfront.net/file/product_badges/1.1/badge_text_mksonly_v2.png',
  //                   badgeIdx: 9,
  //                   priority: 0,
  //                   created_date: '2022-04-05 07:57:54.000000',
  //                   name: 'MAKESTAR Only'
  //                 }
  //               ],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-08-23 06:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             }
  //           ],
  //           DCI024: [
  //             {
  //               pdCd: 'PD27373',
  //               pdNm: "DKB (다크비) 2024 SEASON'S GREETINGS [MECHANIC]",
  //               price: 53000,
  //               salePrice: 43000,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/OST/DKB_2024 SG_썸네일.jpg',
  //               dcYn: 'Y',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'P',
  //               dcRatio: 0,
  //               dcFee: 10000,
  //               regDttm: '2023-12-07 01:31:06',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 1,
  //               erInfoI18n: {
  //                 ko: 1231.52,
  //                 en: 1,
  //                 zh: 7.13,
  //                 ja: 149.63,
  //                 es: 0.93
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI024',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '시즌그리팅',
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2023-12-07 03:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD27341',
  //               pdNm: "TEMPEST(템페스트) 2024 SEASON'S GREETINGS [OUR-TEEN]",
  //               price: 50000,
  //               salePrice: 43000,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/OST/(에버라인)231205_템페스트_2024 SG_썸네일.jpg',
  //               dcYn: 'Y',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'P',
  //               dcRatio: 0,
  //               dcFee: 7000,
  //               regDttm: '2023-12-06 06:13:13',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 4,
  //               erInfoI18n: {
  //                 ko: 1231.52,
  //                 en: 1,
  //                 zh: 7.13,
  //                 ja: 149.63,
  //                 es: 0.93
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI024',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '시즌그리팅',
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2023-12-06 06:11:30',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD27223',
  //               pdNm: '김현중 2024 시즌그리팅 [종합현중세트] Limited Edition',
  //               price: 49000,
  //               salePrice: 40000,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/OST/썸네일_최종.webp',
  //               dcYn: 'Y',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'P',
  //               dcRatio: 0,
  //               dcFee: 9000,
  //               regDttm: '2023-12-04 06:12:04',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 4,
  //               erInfoI18n: {
  //                 ko: 1231.52,
  //                 en: 1,
  //                 zh: 7.13,
  //                 ja: 149.63,
  //                 es: 0.93
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI024',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '시즌그리팅',
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2023-12-04 06:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD27157',
  //               pdNm: "BAE173 2024 SEASON'S GREETINGS [SHINNING DAY] - DESK CALENDAR ver.",
  //               price: 46500,
  //               salePrice: 38000,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/OST/BAE173_CALENDAR KIT VER.webp',
  //               dcYn: 'Y',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'P',
  //               dcRatio: 0,
  //               dcFee: 8500,
  //               regDttm: '2023-12-01 06:14:24',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 17,
  //               erInfoI18n: {
  //                 ko: 1231.52,
  //                 en: 1,
  //                 zh: 7.13,
  //                 ja: 149.63,
  //                 es: 0.93
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI024',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '시즌그리팅',
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2023-12-01 06:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             }
  //           ],
  //           DCI017: [
  //             {
  //               pdCd: 'PD36554',
  //               pdNm: 'Various Artists [낮과 밤이 다른 그녀] OST',
  //               price: 23300,
  //               salePrice: 19300,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/DKZ/팩샷 (2).jpg',
  //               dcYn: 'Y',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'P',
  //               dcRatio: 0,
  //               dcFee: 4000,
  //               regDttm: '2024-07-26 01:18:32',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 49992,
  //               erInfoI18n: {
  //                 ko: 1231.52,
  //                 en: 1,
  //                 zh: 7.13,
  //                 ja: 149.63,
  //                 es: 0.93
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI017',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '드라마',
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-07-26 02:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD36435',
  //               pdNm: 'Various Artists [놀아주는 여자] OST (2CD)',
  //               price: 34200,
  //               salePrice: 28200,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/DKZ/놀아주는여자_예판썸네일.jpg',
  //               dcYn: 'Y',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'P',
  //               dcRatio: 0,
  //               dcFee: 6000,
  //               regDttm: '2024-07-25 01:30:01',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 49999,
  //               erInfoI18n: {
  //                 ko: 1231.52,
  //                 en: 1,
  //                 zh: 7.13,
  //                 ja: 149.63,
  //                 es: 0.93
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI017',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '드라마',
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-07-25 02:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD36146',
  //               pdNm: 'Various Artists [2024 데시벨 OST]',
  //               price: 21800,
  //               salePrice: 17800,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/BOA/L200003019_신보안내서_2024 데시벨 OST_자켓이미지 (1).jpg',
  //               dcYn: 'Y',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'P',
  //               dcRatio: 0,
  //               dcFee: 4000,
  //               regDttm: '2024-07-16 06:49:55',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 49997,
  //               erInfoI18n: {
  //                 ko: 1231.52,
  //                 en: 1,
  //                 zh: 7.13,
  //                 ja: 149.63,
  //                 es: 0.93
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI017',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '드라마',
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-07-16 07:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD35281',
  //               pdNm: 'Various Artists [이재, 곧 죽습니다OST] [LP]',
  //               price: 66500,
  //               salePrice: 56500,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/OST/V.A_이재, 곧 죽습니다 OST LP_썸네일.jpg',
  //               dcYn: 'Y',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'P',
  //               dcRatio: 0,
  //               dcFee: 10000,
  //               regDttm: '2024-06-24 06:34:25',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 50000,
  //               erInfoI18n: {
  //                 ko: 1231.52,
  //                 en: 1,
  //                 zh: 7.13,
  //                 ja: 149.63,
  //                 es: 0.93
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI017',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '드라마',
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-06-24 07:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             }
  //           ],
  //           DCI028: [
  //             {
  //               pdCd: 'PD37600',
  //               pdNm: '라이즈(RIIZE) [BOSS] RIIZE POP-UP EXHIBITION PHOTOBOOK',
  //               price: 35000,
  //               salePrice: 28000,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/DKZ/보스라이즈썸네일.jpg',
  //               dcYn: 'Y',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'P',
  //               dcRatio: 0,
  //               dcFee: 7000,
  //               regDttm: '2024-08-16 01:12:14',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 50000,
  //               erInfoI18n: {
  //                 ko: 1223.99,
  //                 en: 1,
  //                 zh: 7.1,
  //                 ja: 148.94,
  //                 es: 0.91
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI028',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '최신 상품',
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-08-16 01:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD37087',
  //               pdNm: 'UKISS (유키스) 2024 TOUR MD [UCHIWA + STICKER] (훈 ver.)',
  //               price: 20000,
  //               salePrice: 16000,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/BOA/유키스_thumb.jpg',
  //               dcYn: 'Y',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'P',
  //               dcRatio: 0,
  //               dcFee: 4000,
  //               regDttm: '2024-08-07 01:23:57',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 49999,
  //               erInfoI18n: {
  //                 ko: 1235.08,
  //                 en: 1,
  //                 zh: 7.08,
  //                 ja: 144.54,
  //                 es: 0.91
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI028',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '최신 상품',
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-08-07 02:00:00',
  //               saleEdDt: '2024-09-02 14:59:59',
  //               saleEdTermYn: 'N',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD37086',
  //               pdNm: 'UKISS (유키스) 2024 TOUR MD [UCHIWA + STICKER] (일라이 ver.)',
  //               price: 20000,
  //               salePrice: 16000,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/BOA/유키스_thumb.jpg',
  //               dcYn: 'Y',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'P',
  //               dcRatio: 0,
  //               dcFee: 4000,
  //               regDttm: '2024-08-07 01:23:10',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 50000,
  //               erInfoI18n: {
  //                 ko: 1235.08,
  //                 en: 1,
  //                 zh: 7.08,
  //                 ja: 144.54,
  //                 es: 0.91
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI028',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '최신 상품',
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-08-07 02:00:00',
  //               saleEdDt: '2024-09-02 14:59:59',
  //               saleEdTermYn: 'N',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD37085',
  //               pdNm: 'UKISS (유키스) 2024 TOUR MD [UCHIWA + STICKER] (기섭 ver.)',
  //               price: 20000,
  //               salePrice: 16000,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/BOA/유키스_thumb.jpg',
  //               dcYn: 'Y',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'P',
  //               dcRatio: 0,
  //               dcFee: 4000,
  //               regDttm: '2024-08-07 01:22:26',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 50000,
  //               erInfoI18n: {
  //                 ko: 1235.08,
  //                 en: 1,
  //                 zh: 7.08,
  //                 ja: 144.54,
  //                 es: 0.91
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI028',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '최신 상품',
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-08-07 02:00:00',
  //               saleEdDt: '2024-09-02 14:59:59',
  //               saleEdTermYn: 'N',
  //               brandIdx: 0
  //             }
  //           ],
  //           DCI001: [
  //             {
  //               pdCd: 'PD37981',
  //               pdNm: '윤하 (YOUNHA) 7th Album [GROWTH THEORY]',
  //               price: 24000,
  //               salePrice: 20000,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/(1)/L200003083_신보안내서_윤하_정규 7집(데뷔 20주년)_자켓이미지.jpg',
  //               dcYn: 'Y',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'P',
  //               dcRatio: 0,
  //               dcFee: 4000,
  //               regDttm: '2024-08-23 05:42:37',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 50000,
  //               erInfoI18n: {
  //                 ko: 1203.05,
  //                 en: 1,
  //                 zh: 7.06,
  //                 ja: 145.56,
  //                 es: 0.9
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI001',
  //               iconNew: 'new',
  //               recomYn: 'N',
  //               ctgrNm: '케이팝 앨범',
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-08-23 06:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD37974',
  //               pdNm: 'HYNN(박혜원) 미니 [하계 : 夏季]',
  //               price: 23300,
  //               salePrice: 19300,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/OST/L200003095_신보안내서_HYNN(박혜원) 미니_자켓이미지.jpg',
  //               dcYn: 'Y',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'P',
  //               dcRatio: 0,
  //               dcFee: 4000,
  //               regDttm: '2024-08-23 04:44:57',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 50000,
  //               erInfoI18n: {
  //                 ko: 1203.14,
  //                 en: 1,
  //                 zh: 7.05,
  //                 ja: 145.42,
  //                 es: 0.9
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI001',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '케이팝 앨범',
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-08-23 05:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD37972',
  //               pdNm: 'Jayci yucca X SLO [17 Diary]',
  //               price: 21800,
  //               salePrice: 17800,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/OST/Jayci yuccaXSLO_17 Diary_썸네일.jpg',
  //               dcYn: 'Y',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'P',
  //               dcRatio: 0,
  //               dcFee: 4000,
  //               regDttm: '2024-08-23 04:37:28',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 50000,
  //               erInfoI18n: {
  //                 ko: 1203.14,
  //                 en: 1,
  //                 zh: 7.05,
  //                 ja: 145.42,
  //                 es: 0.9
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI001',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '케이팝 앨범',
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-08-23 05:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD37885',
  //               pdNm: '드림캐쳐 (Dreamcatcher) 데뷔 싱글 [악몽(惡夢)]',
  //               price: 23300,
  //               salePrice: 19300,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/DKZ/[지니뮤직] 드림캐쳐 싱글1집 [악몽] 썸네일.jpg',
  //               dcYn: 'Y',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'P',
  //               dcRatio: 0,
  //               dcFee: 4000,
  //               regDttm: '2024-08-22 05:28:07',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 49919,
  //               erInfoI18n: {
  //                 ko: 1200.69,
  //                 en: 1,
  //                 zh: 7.05,
  //                 ja: 144.96,
  //                 es: 0.9
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI001',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '케이팝 앨범',
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-08-22 06:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             }
  //           ],
  //           DCI044: [
  //             {
  //               pdCd: 'PD29268',
  //               pdNm: "82MAJOR 1st CONCERT '82 PEOPLE' OFFICIAL MD - 세트 (7종)",
  //               price: 97000,
  //               salePrice: 97000,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/(1)/82major7.jpg',
  //               dcYn: 'N',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'P',
  //               dcRatio: 0,
  //               dcFee: 0,
  //               regDttm: '2024-02-02 08:45:24',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 7,
  //               erInfoI18n: {
  //                 ko: 1231.52,
  //                 en: 1,
  //                 zh: 7.13,
  //                 ja: 149.63,
  //                 es: 0.93
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI044',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '최신 상품',
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-02-06 07:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD29267',
  //               pdNm: "82MAJOR 1st CONCERT '82 PEOPLE' OFFICIAL MD - 증명사진 세트",
  //               price: 10000,
  //               salePrice: 10000,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/(1)/782M.jpg',
  //               dcYn: 'N',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'P',
  //               dcRatio: 0,
  //               dcFee: 0,
  //               regDttm: '2024-02-02 08:33:27',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 49,
  //               erInfoI18n: {
  //                 ko: 1231.52,
  //                 en: 1,
  //                 zh: 7.13,
  //                 ja: 149.63,
  //                 es: 0.93
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI044',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '최신 상품',
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-02-06 07:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD29255',
  //               pdNm: "82MAJOR 1st CONCERT '82 PEOPLE' OFFICIAL MD - 스티커 세트",
  //               price: 9000,
  //               salePrice: 9000,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/(1)/682M.jpg',
  //               dcYn: 'N',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'P',
  //               dcRatio: 0,
  //               dcFee: 0,
  //               regDttm: '2024-02-02 08:30:06',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 118,
  //               erInfoI18n: {
  //                 ko: 1231.52,
  //                 en: 1,
  //                 zh: 7.13,
  //                 ja: 149.63,
  //                 es: 0.93
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI044',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '최신 상품',
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-02-06 07:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD29254',
  //               pdNm: "82MAJOR 1st CONCERT '82 PEOPLE' OFFICIAL MD - 엽서 세트",
  //               price: 10000,
  //               salePrice: 10000,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/(1)/582M.jpg',
  //               dcYn: 'N',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'P',
  //               dcRatio: 0,
  //               dcFee: 0,
  //               regDttm: '2024-02-02 08:24:55',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 128,
  //               erInfoI18n: {
  //                 ko: 1231.52,
  //                 en: 1,
  //                 zh: 7.13,
  //                 ja: 149.63,
  //                 es: 0.93
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI044',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '최신 상품',
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-02-06 07:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             }
  //           ],
  //           DCI010: [
  //             {
  //               pdCd: 'PD38037',
  //               pdNm: '심아일랜드 [Cheol-Su]',
  //               price: 17900,
  //               salePrice: 14900,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/DKZ/심 커버.jpg',
  //               dcYn: 'Y',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'P',
  //               dcRatio: 0,
  //               dcFee: 3000,
  //               regDttm: '2024-08-26 02:26:23',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 50000,
  //               erInfoI18n: {
  //                 ko: 1187.54,
  //                 en: 1,
  //                 zh: 7.03,
  //                 ja: 143.55,
  //                 es: 0.89
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI010',
  //               iconNew: 'new',
  //               recomYn: 'N',
  //               ctgrNm: '최신 상품',
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-08-26 02:24:28',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD37981',
  //               pdNm: '윤하 (YOUNHA) 7th Album [GROWTH THEORY]',
  //               price: 24000,
  //               salePrice: 20000,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/(1)/L200003083_신보안내서_윤하_정규 7집(데뷔 20주년)_자켓이미지.jpg',
  //               dcYn: 'Y',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'P',
  //               dcRatio: 0,
  //               dcFee: 4000,
  //               regDttm: '2024-08-23 05:42:37',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 50000,
  //               erInfoI18n: {
  //                 ko: 1203.05,
  //                 en: 1,
  //                 zh: 7.06,
  //                 ja: 145.56,
  //                 es: 0.9
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI010',
  //               iconNew: 'new',
  //               recomYn: 'N',
  //               ctgrNm: '케이팝 앨범',
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-08-23 06:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD37974',
  //               pdNm: 'HYNN(박혜원) 미니 [하계 : 夏季]',
  //               price: 23300,
  //               salePrice: 19300,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/OST/L200003095_신보안내서_HYNN(박혜원) 미니_자켓이미지.jpg',
  //               dcYn: 'Y',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'P',
  //               dcRatio: 0,
  //               dcFee: 4000,
  //               regDttm: '2024-08-23 04:44:57',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 50000,
  //               erInfoI18n: {
  //                 ko: 1203.14,
  //                 en: 1,
  //                 zh: 7.05,
  //                 ja: 145.42,
  //                 es: 0.9
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI010',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '케이팝 앨범',
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-08-23 05:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD37972',
  //               pdNm: 'Jayci yucca X SLO [17 Diary]',
  //               price: 21800,
  //               salePrice: 17800,
  //               fileUrl: 'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/OST/Jayci yuccaXSLO_17 Diary_썸네일.jpg',
  //               dcYn: 'Y',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'P',
  //               dcRatio: 0,
  //               dcFee: 4000,
  //               regDttm: '2024-08-23 04:37:28',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 50000,
  //               erInfoI18n: {
  //                 ko: 1203.14,
  //                 en: 1,
  //                 zh: 7.05,
  //                 ja: 145.42,
  //                 es: 0.9
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI010',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: '케이팝 앨범',
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2024-08-23 05:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             }
  //           ],
  //           DCI043: [
  //             {
  //               pdCd: 'PD23522',
  //               pdNm: "LE'V 'THE FIRST FANMEETING [TIME]’ PHOTOCARD SET - B.VER",
  //               price: 10000,
  //               salePrice: 10000,
  //               fileUrl: "https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/SHOP2/LE'V_PHOTOCARDSET B.ver_thumbnail.webp",
  //               dcYn: 'N',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'R',
  //               dcRatio: 0,
  //               dcFee: 0,
  //               regDttm: '2023-09-15 05:01:16',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 72,
  //               erInfoI18n: {
  //                 ko: 1231.52,
  //                 en: 1,
  //                 zh: 7.13,
  //                 ja: 149.63,
  //                 es: 0.93
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI043',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: "LE'V MD",
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2023-09-18 04:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD23520',
  //               pdNm: "LE'V 'THE FIRST FANMEETING [TIME]’ PHOTOCARD SET - A.VER",
  //               price: 10000,
  //               salePrice: 10000,
  //               fileUrl: "https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/SHOP2/LE'V_PHOTOCARDSET A.ver_thumbnail.webp",
  //               dcYn: 'N',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'R',
  //               dcRatio: 0,
  //               dcFee: 0,
  //               regDttm: '2023-09-15 04:58:52',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 92,
  //               erInfoI18n: {
  //                 ko: 1231.52,
  //                 en: 1,
  //                 zh: 7.13,
  //                 ja: 149.63,
  //                 es: 0.93
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI043',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: "LE'V MD",
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2023-09-18 04:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD23519',
  //               pdNm: "LE'V 'THE FIRST FANMEETING [TIME]’ T-SHIRTS",
  //               price: 33000,
  //               salePrice: 33000,
  //               fileUrl: "https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/SHOP/LE'V_TSHIRTS_thumbnail(2).webp",
  //               dcYn: 'N',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'R',
  //               dcRatio: 0,
  //               dcFee: 0,
  //               regDttm: '2023-09-15 02:47:11',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 38,
  //               erInfoI18n: {
  //                 ko: 1231.52,
  //                 en: 1,
  //                 zh: 7.13,
  //                 ja: 149.63,
  //                 es: 0.93
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI043',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: "LE'V MD",
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2023-09-18 04:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             },
  //             {
  //               pdCd: 'PD23506',
  //               pdNm: "LE'V 'THE FIRST FANMEETING [TIME]’ POSTER SET",
  //               price: 15000,
  //               salePrice: 15000,
  //               fileUrl: "https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/product/SHOP2/LE'V_POSTER_thumbnail.webp",
  //               dcYn: 'N',
  //               dcTermYn: 'N',
  //               dcStDt: null,
  //               dcEdDt: null,
  //               dcDiv: 'R',
  //               dcRatio: 0,
  //               dcFee: 0,
  //               regDttm: '2023-09-14 08:36:09',
  //               saleStat: 'S',
  //               stkCntDpYn: 'N',
  //               stkCnt: 97,
  //               erInfoI18n: {
  //                 ko: 1231.52,
  //                 en: 1,
  //                 zh: 7.13,
  //                 ja: 149.63,
  //                 es: 0.93
  //               },
  //               langCrcy: 'KRW',
  //               dpCtgrCd: 'DCI043',
  //               iconNew: '',
  //               recomYn: 'N',
  //               ctgrNm: "LE'V MD",
  //               badgeList: [],
  //               pdType: 'N',
  //               specialPdYn: 'N',
  //               displaySaleTerm: 'N',
  //               displaySaleQuantity: 'N',
  //               totalSaleQuantity: 0,
  //               saleStDt: '2023-09-18 04:00:00',
  //               saleEdDt: null,
  //               saleEdTermYn: 'Y',
  //               brandIdx: 0
  //             }
  //           ]
  //         }
  //       }
  //     })
  //   )
  // })
]
